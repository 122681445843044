<template>
      <div class="mr-2" :class="showMenu ? 'show-menu':'hide-menu'">
            <div class="log-panel">
                  <div class="log-top"></div>
                  <div class="log-icon" >
                      <button @click="changeColor" class="color-btn" v-bind:style="{ color: fontColor }"><i class="fas fa-user-circle"></i></button>
                  </div>
                  <div class="log-user">{{this.$store.getters.userData.userName}}</div>
                  <div class="log-agency">{{this.$store.getters.userData.agency}}</div>
                  <ul>
                        <li>
                              <router-link :to="{ name: 'Home'}">
                              <button class="nav-btn" >Calendar</button>
                              </router-link>
                        </li>
                        <li v-if="uFnCheck(1) && !isMobile">
                              <router-link :to="{ name: 'MissingJobs'}">
                              <button class="nav-btn" >Jobs swapping</button>
                              </router-link>
                        </li>
                         <!-- <li v-if="this.$store.getters.userData.role === 'Admin'">
                              <router-link :to="{ name: 'NoOwnerJobs'}">
                              <button class="nav-btn" >No Owner Jobs</button>
                              </router-link>
                        </li> -->
                        <li v-if="uFnCheck(2) && !isMobile">
                              <router-link :to="{ name: 'Projects'}">
                              <button class="nav-btn" >Projects</button>
                              </router-link>
                        </li>
                        <li v-if="uFnCheck(3) && !isMobile">
                              <router-link :to="{ name: 'FnlDashboard'}">
                              <button class="nav-btn" >Finances</button>
                              </router-link>
                        </li>
                        <li>
                              <router-link :to="{ name: 'Settings'}">
                              <button class="nav-btn" >Settings</button>
                              </router-link>
                        </li>
                        <li v-if="uFnCheck(6)">
                              <router-link :to="{ name: 'Dashboard'}">
                              <button class="nav-btn" >Reports</button>
                              </router-link>
                        </li>
                        <li v-if="uFnCheck(10)">
                              <router-link :to="{ name: 'AdmDashboard'}">
                              <button class="nav-btn" >Admin</button>
                              </router-link>
                        </li>
                        
                        <li><button class="nav-btn" @click="CookieRemove()">Log out</button></li>
                  </ul>
                  <div class="log-v">
                        <!-- <router-link :to="{ name: 'About'}"><span>v. {{version()}}</span></router-link> -->
                        <span>v. {{version()}}</span>
                        <span>&copy; LiquidThread 2024 </span>
                        <div :class="['log-logo', agencyClass]"></div>
                        
                  </div>
            </div>
      </div>
</template>

<script>

import shared from "@/shared";

export default {

  data: function () {
    return {
      userRole: null,
      userTeam: null,
      fontColor: this.$store.getters.getLogoColor,
      agencyId: this.$store.getters.userData.agencyId,
      agencyClass: '',
      isMobile: this.$store.getters.isMobile
      };
  },

  props: {
            showMenu: Boolean
  },

  created(){
        this.CookieRemove = shared.CookieRemove;
        this.uFnCheck = shared.uFnCheck;
        this.randomRGB = shared.randomRGB;
        this.agencyLogoClass = shared.agencyLogoClass;
        //this.userRole = this.$store.getters.userData.role;
        //this.userTeam = this.$store.getters.userData.team;

        this.agencyClass = this.agencyLogoClass(this.agencyId);

        //console.log('agencyId: '+this.agencyId);

        if (!this.isMobile && localStorage.getItem('isMobile') == 1 ) {
          this.$store.commit('setIsMobile', true);
          this.isMobile = true;
        }
  },
  mounted(){
        this.agencyClass = this.agencyLogoClass(this.agencyId);

  },

  methods: {
    version(){
      return localStorage.getItem('version');
    },

    changeColor() {
      this.fontColor = this.randomRGB();
      this.$store.commit('setLogoColor', this.fontColor);
    },
    
        
  }

}
</script>

<style scoped>


.mr-2 {
  position: absolute;
  right: 16px;
  top: -228px;
  display: block;
  width: 160px;
  background-color: #f5f5ed;
  background-color: white;
  box-shadow: 0px 3px 16px -5px rgb(120 122 123);
  z-index: 2;
  
}


body.mobile .mr-2 {
  right: 0;
  top: -244px;
  width: 100%;
  box-shadow: none;  
}

.mr-2 .log-panel {
  border: 1px solid #ddd;
}

.log-panel .nav-btn {
  width: 92%;
  font-size: 12px;
  line-height: 14px;
  padding: 6px 4px;
  border: 1px solid #ddd;
}

.mr-2 .log-top {
  height: 34px;
  background-color: #ddd;
}

.mr-2 .log-icon {
  margin-top: -29px;
}

.mr-2 .log-icon .color-btn {
  font-size: 40px;
  color: #c25a10;
  background-color: transparent;
  border: none;
}



.mr-2 .log-user {
  font-size: 12px;
  font-weight: bold;
  overflow-wrap: break-word;
}

.mr-2 .log-agency {
  font-size: 10px;
  font-weight: normal;
  overflow-wrap: break-word;
  margin-top: 2px;
  color: #a3a3a3;

}

.mr-2 ul,
.mr-2 ul li {
  padding: 0;
  margin:  0;
  list-style: none;
}

.mr-2 ul {
  margin: 16px 0;
}

.mr-2 ul li {
  padding: 4px 0;
  margin: 0 -1px;

}

.mr-2 ul li:last-child button {
  background: linear-gradient(to right bottom, #fff8e3, #ffdb6e);;

}

.mr-2 .log-v {
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  color: #a3a3a3;
  margin-top: 32px;
  padding: 5px;
  text-align: left;
  position: relative;
}

.mr-2 .log-v a {
  text-decoration: none;
  color: #a3a3a3;
}

.mr-2 .log-v .log-logo {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 25px;
  width: 100%;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.mr-2 .log-v .log-lqt {  
  background-image: url('../assets/lqt-logo-2.png');  
}

.mr-2 .log-v .log-spark {
  background-image: url('../assets/spark-logo.png');
  height: 18px;
  bottom: 27px;
}

.mr-2 .log-v .log-zenith {
  background-image: url('../assets/zenith-logo.png');
  height: 26px;
}

.mr-2 .log-v .log-pmx {
  background-image: url('../assets/pmx-logo.png');
  height: 26px;
}

.mr-2 .log-v .log-performics {
  background-image: url('../assets/performics-logo.png');
  height: 18px;
  bottom: 28px;
}

.mr-2 .log-v .log-yotta {  
  background-image: url('../assets/yotta-logo.svg');
  height: 20px;
}

.mr-2 .log-v .log-starcom {  
  background-image: url('../assets/starcom-logo.png');
}

.mr-2 .log-v .log-pg {  
  height: 30px;
  background-image: url('../assets/pg-logo.png');
}

.dark-mode .mr-2 {
  background-color: #1c2630;
  box-shadow: 0px 3px 16px -5px rgb(0 0 0);
}

.dark-mode .mr-2 .log-top {
  background-color: #2c3e50;
}

.dark-mode .mr-2 .log-panel {
  border: 1px solid #3e6487;
}

.dark-mode .mr-2 ul li:last-child button {
    filter: brightness(58%);
    color: black;    
}

.dark-mode .log-panel .nav-btn {
  border: 1px solid #5c5c5c;
}

.dark-mode .mr-2 .log-v .log-lqt {
  background-image: url('../assets/lqt-logo-gr.png');
}

.dark-mode .mr-2 .log-v .log-spark {
  background-image: url('../assets/spark-logo-gr.png');
  height: 18px;
  bottom: 27px;
}

.dark-mode .mr-2 .log-v .log-zenith {
  background-image: url('../assets/zenith-logo-gr.png');
  height: 26px;
}

.dark-mode .mr-2 .log-v .log-pmx {
  background-image: url('../assets/pmx-logo-gr.png');
  height: 26px;
}

.dark-mode .mr-2 .log-v .log-performics {
  background-image: url('../assets/performics-logo-gr.png');
  height: 18px;
  bottom: 28px;
}

.dark-mode .mr-2 .log-v .log-yotta {
  background-image: url('../assets/yotta-logo-gr.svg');
  height: 20px;
}

.dark-mode .mr-2 .log-v .log-starcom {
  background-image: url('../assets/starcom-logo-gr.png');
}

.dark-mode .mr-2 .log-v .log-pg {
  height: 30px;
  background-image: url('../assets/pg-logo-gr.png');
}


</style>