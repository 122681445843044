<template>
  <nav-header :showNav="true" :clearButtons="true">
      <template v-slot:navButtons>  
          <button class="nav-btn" @click="selectedDate = new Date()">Today</button>
          <button class="nav-btn" @click="previous()"><i class="angle prev"></i></button>
          <button class="nav-btn" @click="next()"><i class="angle"></i></button> 
      </template>
      
      <template v-slot:navView>
          <h3>Calendar</h3>
      </template>      
     
      <template v-slot:navLog>
          <button class="nav-btn" @click="showMenu = !showMenu"><i :class="[showMenu ? 'fa-ellipsis-v':'fa-ellipsis-h', 'fas']"></i></button>
      </template>
  </nav-header>

  <div>{{selectedDate.format()}}</div>
  <nav-menu :showMenu="showMenu" ></nav-menu>
</template>

<script>
import shared from "@/shared";
//import calendar from "../components/Calendar.vue";

export default {

  name: "App",

 data: function () {
    return {
      loader: false,
      error: null,
      showMenu: false,
      selectedDate: new Date(),    

    };
  },
  
  created() {
    this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
    this.CookieCheckGoToLogin();
  },

  mounted() {
    this.addBodyClass('mobile');
  },

  unmounted() {
    this.removeBodyClass('mobile');
  },

  methods: {

    addBodyClass(className) {
      document.body.classList.add(className);
    },

    removeBodyClass(className) {
      document.body.classList.remove(className);
    },

    previous() {
      try {
        this.selectedDate = new Date(this.selectedDate.getTime()).addDays(-1);
      } catch (error) {
        console.log("--------ERROR ------------");
        console.dir(error);
      }
    },

    next() {
      try {
        this.selectedDate = new Date(this.selectedDate.getTime()).addDays(1);
      } catch (error) {
        console.log("--------ERROR ------------");
        console.dir(error);
      }
    },
  }
};
</script>

<style>

  .mobile .invalid-wrap {
    display: none;
  }

  body.mobile .nav-btn {
    padding: 6px 9px 6px;
    font-size: 12px;
    line-height: 10px;
    min-width: 32px;
  }


</style>