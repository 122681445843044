<template>
    <div>
      <h3>{{title}}</h3>
    </div>

  <div v-if="data.Code != 'OK'">
    <form role="form" class="text-start" @submit.prevent="loginForm">
      <div class="i-holder">
        
        <input type="email" class="form-control" v-model="post.email" placeholder="Email address">
      </div>
      <div class="t-holder">
        <span>Link will be sent to given email address.</span>
      </div>

      <div class="b-holder">
        <button type="submit" class="d-btn"><i class="fas fa-user-check"></i></button>
      </div>
      
    </form>
  </div>

      <div>
            <div class="msg msg-ok" v-if="data.Code === 'OK'">
                  <p>Link has been sent to your email address.</p>
            </div>
            <div class="msg msg-err" v-else>
                  <p>{{data.Text}}</p>
            </div>
      </div>


</template>

<script>

import authService from "@/services/auth.js";

export default {
  name: "LoginPanel",
  props: {
    title: String,
  },
  data() {
    return {
      post: {},
      data: {}
    };
  },
  methods: {
    async loginForm() {
      this.data = await authService.createLoginPost(this.post);

    }
  }
};
</script>


<style scoped>
h3 {
 
  font-size: 22px;
  margin: 0;
  margin-top: 20px;
}

.i-holder {
      margin-top: 26px;
}

.b-holder {
      margin-top: 20px;
}

.b-holder .d-btn {
      font-size: 15px;
      padding: 6px;
      width: 84px;
}

.t-holder {
  margin-top: 24px;
}

.t-holder span {
  font-size: 14px;
}

.form-control {
      width: 324px;
      font-size: 16px;
      font-family: 'Lato-Regular';
      color: #667;
}

.msg {
      color: #c25a10;
}

.msg-ok {
      margin-top: 38px;
}

.msg-ok p {
      font-size: 18px;
}

.msg-err p {
  margin-top: 2px;
}



</style>
