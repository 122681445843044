<template>
    <Loader :loader="loader" />
   
    <div class="inner-wrap2 w-fin">
      <!-- <p style="font-size: 28px; font-family: 'Anton-Regular';">coming soon</p> -->
      <h3>Entries PC by month</h3>
      <div class="criteria">
        <div class="control-wrap r-fin">
            <span>Year: &nbsp;</span>
              <vue-select 
                v-model="year" 
                :options="years" 
                label-by="value" 
                value-by="no" 
                :blur="onDateChange"
                close-on-select />


            <span>Quarterly: </span>
            <label class="rad-label">
                  <input type="checkbox" class="rad-input" name="quarterly" v-model="quarterly" @change="onDateChange()" />
                  <div class="rad-design"></div>
            </label>
           
        </div>
        
        <div class="control-wrap r-fin excel">
            <vue-excel-xlsx class="d-btn ex-btn"
            :data="itemsEx"
            :columns="headersEx"
            :file-name="filename"
            :file-type="'xlsx'"
            :sheet-name="'Entries PC'"
            :disabled="itemsEx == null"
            title="export to Excel"
            >
            <i class="fas fa-file-excel"></i>
          </vue-excel-xlsx>
        </div>
      </div>
      <div class="radio-type">
            <ul class="answer-list">
                  <li>
                        <label class="rad-label">
                          <input type="radio" class="rad-input" name="pc1" value="1" v-model="rapType" :checked="rapType == 1" @change=" onDateChange()" />
                          <div class="rad-design"></div>
                          <div class="rad-text">TS Amount (PC)</div>
                        </label>                        
                  </li>
                  <li>
                        <label class="rad-label">
                          <input type="radio" class="rad-input" name="pc2" value="2" v-model="rapType" :checked="rapType == 2" @change="onDateChange()" />
                          <div class="rad-design"></div>
                          <div class="rad-text">TS Amount (PC-30)</div>
                        </label>                        
                  </li>
             
            </ul>
        </div>
        <div v-if="uFnCheck(4)" class="criteria">
          <div class="control-wrap t-list">
                  <span>Team name: &nbsp;</span>
                  <vue-select
                        v-model="teamId" 
                        :options="teamList" 
                        label-by="ProjectGroup" 
                        value-by="Id"
                        placeholder="select a team" 
                        :blur="onDateChange"
                        close-on-select
                        clear-on-select 
                        clear-on-close 
                        >
                        <template #dropdown-item="{ option }">
                        <div :class="'ft-'+option.Financial.toString()">{{ option.ProjectGroup }}</div>
                        </template>
                  </vue-select>
                                             
          </div>
         
        </div>

      <div class="center-pan">
         
        <div v-if="!quarterly" class="m-view">
          <vue-good-table
            :columns="headersM"
            :rows="itemsM"
            row-style-class="rowStyleClass"
            :line-numbers="false"
            :search-options="{enabled: true}"
            :sort-options="{enabled: true}"  
            :pagination-options="{
              enabled: true,
              mode: 'records'
            }" 
                          
          >
            <template v-slot:emptystate>
                No data
            </template>

            <template #table-column="props">
              <div v-if="regMonth(props.column.field)">
                <div class="abs-m">
                  {{props.column.label2}} 
                </div>
                <div>
                  {{props.column.labelT}}
                </div>
              </div>
             
              <div v-else>
                  {{props.column.labelT}}
              </div>
            </template>
            
          </vue-good-table>

        </div>
        <div v-else class="q-view">
          <vue-good-table
            :columns="headersQ"
            :rows="itemsQ"
            row-style-class="rowStyleClass"
            :line-numbers="false"
            :search-options="{enabled: true}"
            :sort-options="{enabled: true}"  
            :pagination-options="{
              enabled: true,
              mode: 'records'
            }" 
                          
          >
            <template v-slot:emptystate>
                No data
            </template>

            <template #table-column="props">
              <div v-if="regMonth(props.column.field)">
                <div class="abs-m">
                  {{props.column.label2}} 
                </div>
                <div>
                  {{props.column.labelT}}
                </div>
              </div>
             
              <div v-else>
                  {{props.column.labelT}}
              </div>
            </template>
            
          </vue-good-table>

        </div>
        
      </div>
        

    </div>
 
  
</template>

<script>
import shared from "@/shared";
import moment from "moment";
//import reportService from "@/services/reports.js";
import financesService from "@/services/finances.js";
import Loader from '../../components/Loader.vue';
// import { DoughnutChart } from 'vue-chart-3';
// import { BarChart } from 'vue-chart-3';
// import { Chart, registerables } from "chart.js";

//Chart.register(...registerables);

export default {
  components: {
      Loader, 
      //DoughnutChart, 
      //BarChart 
  },
  name: "App",
  
  

  data: function () {
    return {
      year: 2022,
      showNav: true,
      error: null,
      loader: false,
      teamId: 0,
      agencyId: this.$store.getters.userData.agencyId,
      quarterly: false,
      rapType: 1, // 1 - PC, 2 - PC-30
      filename: 'filename',
      years: [],
      headersM: [
                        { label2: "Job No", label: "Job No", field: "JobNo", tdClass: 'text-f14' },
                        { label2: "Jan", labelT: "Hrs", label: "Jan-Hrs", field: "m1h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Jan", labelT: "PC", label: "Jan-PC", field: "m1pc", type: 'number', tdClass: 'text-f14' },
                        { label2: "Feb", labelT: "Hrs", label: "Feb-Hrs", field: "m2h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Feb", labelT: "PC", label: "Feb-PC", field: "m2pc", type: 'number', tdClass: 'text-f14' },
                        { label2: "Mar", labelT: "Hrs", label: "Mar-Hrs", field: "m3h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Mar", labelT: "PC", label: "Mar-PC", field: "m3pc", type: 'number', tdClass: 'text-f14' },
                        { label2: "Apr", labelT: "Hrs", label: "Apr-Hrs", field: "m4h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Apr", labelT: "PC", label: "Apr-PC", field: "m4pc", type: 'number', tdClass: 'text-f14' },
                        { label2: "May", labelT: "Hrs", label: "May-Hrs", field: "m5h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "May", labelT: "PC", label: "May-PC", field: "m5pc", type: 'number', tdClass: 'text-f14' },
                        { label2: "Jun", labelT: "Hrs", label: "Jun-Hrs", field: "m6h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Jun", labelT: "PC", label: "Jun-PC", field: "m6pc", type: 'number', tdClass: 'text-f14' },
                        { label2: "Jul", labelT: "Hrs", label: "Jul-Hrs", field: "m7h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Jul", labelT: "PC", label: "Jul-PC", field: "m7pc", type: 'number', tdClass: 'text-f14' },
                        { label2: "Aug", labelT: "Hrs", label: "Aug-Hrs", field: "m8h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Aug", labelT: "PC", label: "Aug-PC", field: "m8pc", type: 'number', tdClass: 'text-f14' },
                        { label2: "Sep", labelT: "Hrs", label: "Sep-Hrs", field: "m9h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Sep", labelT: "PC", label: "Sep-PC", field: "m9pc", type: 'number', tdClass: 'text-f14' },
                        { label2: "Oct", labelT: "Hrs", label: "Oct-Hrs", field: "m10h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Oct", labelT: "PC", label: "Oct-PC", field: "m10pc", type: 'number', tdClass: 'text-f14' },
                        { label2: "Nov", labelT: "Hrs", label: "Nov-Hrs", field: "m11h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Nov", labelT: "PC", label: "Nov-PC", field: "m11pc", type: 'number', tdClass: 'text-f14' },
                        { label2: "Dec", labelT: "Hrs", label: "Dec-Hrs", field: "m12h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Dec", labelT: "PC", label: "Dec-PC", field: "m12pc", type: 'number', tdClass: 'text-f14' }
                  ],
      headersQ: [
                        { label2: "Job No", label: "Job No", field: "JobNo", tdClass: 'text-f14' },
                        { label2: "Q-1", labelT: "Hrs", label: "Q1-Hrs", field: "q1h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Q-1", labelT: "PC", label: "Q1-PC", field: "q1pc", type: 'number', tdClass: 'text-f14' },
                        { label2: "Q-2", labelT: "Hrs", label: "Q2-Hrs", field: "q2h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Q-2", labelT: "PC", label: "Q2-PC", field: "q2pc", type: 'number', tdClass: 'text-f14' },
                        { label2: "Q-3", labelT: "Hrs", label: "Q3-Hrs", field: "q3h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Q-3", labelT: "PC", label: "Q3-PC", field: "q3pc", type: 'number', tdClass: 'text-f14' },
                        { label2: "Q-4", labelT: "Hrs", label: "Q4-Hrs", field: "q4h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Q-4", labelT: "PC", label: "Q4-PC", field: "q4pc", type: 'number', tdClass: 'text-f14' }
                       
                  ],
      itemsM: [],
      itemsQ: [],
      headersEx: [],
      itemsEx: [],
      teamList: [],
      
    };
  },

  created() {
    this.yearList = shared.yearList;
    this.uFnCheck = shared.uFnCheck;

    this.years = this.yearList();
    this.year = this.$store.getters.getCurrYear;

    

    // console.log('years');
    // console.log(this.years);
    // console.log(this.year);

  },
 
  mounted() {
    this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
    this.CookieRemove = shared.CookieRemove;
    this.CookieCheckGoToLogin();   

    //this.getDictTeam();

    financesService
                  .teamList()
                  .then((response) => {

                  if (response.status === 200) {                  
                    
                    this.teamList = response.data;                   
                    this.teamList.unshift({Id: 0, ProjectGroup: '-- All Teams --', Short: 'all', Financial: true});



                    let userData = this.$store.getters.userData;            
                    this.teamId = parseInt(userData.teamId);
                    
                    // console.log('teamId - 1');
                    // console.log(this.teamId);
                    
                    if (typeof this.teamId === 'undefined' ||  this.teamId == null) {                        

                          const data = {
                                ResultName: "teamShortToId",
                                Short: userData.team
                          };

                          financesService
                          .uniRap(data)
                          .then((response) => {

                          if (response.status === 200) {
                              
                                this.teamId = parseInt(JSON.parse(response.data)[0].teamId);   
                                
                                console.log('teamId - 2');
                                console.log(this.teamId);                

                            }
                          
                          })
                          .catch((e) => {
                          this.error = e.message || "Something went wrong!";        
                          
                          if (e.response.status === 401) {
                                this.CookieRemove();
                            }
                          })

                    }


                  }
                  
                  })
                  .catch((e) => {
                  this.error = e.message || "Something went wrong!";
                  
                  
                  if (e.response.status === 401) {
                        this.CookieRemove();
                  }
                  });
    
    


    this.setFileName();   

    setTimeout(() => {
            this.getData();
    }, 360);

   
   
  },
   
  methods: {

    regMonth(str) {
      const regex = /^[a-zA-Z]\d+[a-zA-Z]$/;
      return regex.test(str);
    },

    setFileName() {

      let prefix = this.rapType == 1 ? 'Entries-PC' : 'Entries-PC30';
      let type = this.quarterly ? 'Q' : 'M' ;    

      this.filename = ''+prefix+'-'+type+'-year-'+this.year+'-'+moment().format("YYMMDD.HHmmss");

    },

    getDictTeam() {

            financesService
                  .teamList()
                  .then((response) => {

                  if (response.status === 200) {                  
                    
                    this.teamList = response.data;                   
                    this.teamList.unshift({Id: 0, ProjectGroup: '-- All Teams --', Short: 'all', Financial: true});

                  }
                  
                  })
                  .catch((e) => {
                  this.error = e.message || "Something went wrong!";
                  
                  
                  if (e.response.status === 401) {
                        this.CookieRemove();
                  }
                  });

    },

    getData() {

          let res = this.quarterly ? 'rapEntriesQ' : 'rapEntriesM';

          const data = {
                "ResultName": res,
                "Year": this.year,
                "TeamId": this.teamId,
                "AgencyId": this.agencyId
          };

             console.log("----data----");
             console.log(data); 
          

          this.loader = true;
          financesService
          .uniRap(data)
          .then((response) => {

          if (response.status === 200) {

            
          
            if (this.quarterly) {
              this.itemsQ = JSON.parse(response.data);

              if (this.rapType == 2) {

                this.itemsQ.forEach(element => {
                    element.q1pc = parseFloat(element.q1pc) - (parseFloat(element.q1pc) * 0.3);
                    element.q2pc = parseFloat(element.q2pc) - (parseFloat(element.q2pc) * 0.3);
                    element.q3pc = parseFloat(element.q3pc) - (parseFloat(element.q3pc) * 0.3);
                    element.q4pc = parseFloat(element.q4pc) - (parseFloat(element.q4pc) * 0.3);                   
                    });    

              }

              this.headersEx = this.headersQ;
              this.itemsEx = this.itemsQ;


            }
            else {
              this.itemsM = JSON.parse(response.data);

              if (this.rapType == 2) {

                this.itemsM.forEach(element => {
                    element.m1pc = parseFloat(element.m1pc) - (parseFloat(element.m1pc) * 0.3);
                    element.m2pc = parseFloat(element.m2pc) - (parseFloat(element.m2pc) * 0.3);
                    element.m3pc = parseFloat(element.m3pc) - (parseFloat(element.m3pc) * 0.3);
                    element.m4pc = parseFloat(element.m4pc) - (parseFloat(element.m4pc) * 0.3);
                    element.m5pc = parseFloat(element.m5pc) - (parseFloat(element.m5pc) * 0.3);
                    element.m6pc = parseFloat(element.m6pc) - (parseFloat(element.m6pc) * 0.3);
                    element.m7pc = parseFloat(element.m7pc) - (parseFloat(element.m7pc) * 0.3);
                    element.m8pc = parseFloat(element.m8pc) - (parseFloat(element.m8pc) * 0.3);
                    element.m9pc = parseFloat(element.m9pc) - (parseFloat(element.m9pc) * 0.3);
                    element.m10pc = parseFloat(element.m10pc) - (parseFloat(element.m10pc) * 0.3);
                    element.m11pc = parseFloat(element.m11pc) - (parseFloat(element.m11pc) * 0.3);
                    element.m12pc = parseFloat(element.m12pc) - (parseFloat(element.m12pc) * 0.3);
                    });     

              }

              this.headersEx = this.headersM;
              this.itemsEx = this.itemsM;
            }

            


          }
          
          })
          .catch((e) => {
          this.error = e.message || "Something went wrong!";
          
          
          if (e.response.status === 401) {
                this.CookieRemove();
          }
          })
          .finally(() => {
          this.loader = false;
          });

    },

  
    onDateChange() {

      console.log('data change');

      this.setFileName();
      this.getData();   
      
     },

 
    

  },
  computed: {
    
  }

}
</script>
<style>

  .inner-wrap2.w-fin .criteria .control-wrap.r-fin {
      justify-content: flex-end;
  }

  .inner-wrap2.w-fin .criteria .control-wrap.t-list {
      justify-content: center;
  }

  .inner-wrap2.w-fin .criteria .control-wrap.t-list .vue-select {
      width: 369px;
  }


  .inner-wrap2.w-fin .r-fin .vue-select, 
  .inner-wrap2.w-fin .r-fin .vue-select .vue-dropdown {
    z-index: 6;
  }


  

  .th-custom .abs-m {
      position: absolute;
      top: 0px;
      right: -12px;
      background-color: #F1F3F6;
      padding: 5px 2px 9px 2px;
      z-index: 2;
  }


  .inner-wrap2.w-fin .th-custom {
    padding-top: 30px;
    font-size: 13px;
  }

  
  .inner-wrap2.w-fin .vgt-table th.sortable button:before,
  .inner-wrap2.w-fin .vgt-table th.sortable button:after {
    right: 5px;
    top: 69%;
  }



  .dark-mode .th-custom .abs-m {

      background-color: #2c2f36;

  }

</style>

