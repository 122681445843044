import { createApp } from 'vue';
import App from './App.vue';
import VueCal from 'vue-cal';
import router from "./router";
import store from "./store";
import VueGoodTablePlugin from 'vue-good-table-next';
import 'vue-cal/dist/vuecal.css';
//import 'vue-cal/dist/drag-and-drop.js';
//import 'vue-cal/dist/i18n/pl';
import KProgress from 'k-progress-v3';

//import 'vue-cal/dist/i18n/ca';
import '@vuepic/vue-datepicker/dist/main.css';
import 'vue-next-select/dist/index.min.css';
import 'vue-good-table-next/dist/vue-good-table-next.css';

import VueCookies from 'vue3-cookies';

import BaseDialog from './components/ui/BaseDialog.vue';
import BallPanel from './components/ui/BallPanel.vue';

import BaseButton from './components/ui/BaseButton.vue';
import EntryDescr from './components/ui/EntryDescr.vue';
import NavHeader from './components/NavHeader.vue';
import NavMenu from './components/NavMenu.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import VueNextSelect from 'vue-next-select';
import VueExcelXlsx from "vue-excel-xlsx";
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

// import Slider from '@vueform/slider'
import '@vueform/slider/themes/default.css';





require('@/assets/fonts/awsome/css/all.css');

const app = createApp(App);
app.component('vue-cal', VueCal)
app.component('base-dialog', BaseDialog);
app.component('ball-panel', BallPanel);
app.component('collapse-transition', CollapseTransition);

app.component('base-button', BaseButton);
app.component('date-picker', VueDatePicker);
app.component('vue-select', VueNextSelect);
app.component('entry-descr', EntryDescr);
app.component('nav-header', NavHeader);
app.component('nav-menu', NavMenu);
app.component("EasyDataTable", Vue3EasyDataTable);
// app.component("Slider", Slider);
app.component("k-progress", KProgress);


app.use(VueExcelXlsx);
app.use(VueGoodTablePlugin);
app.use(router);
app.use(store);
app.use(VueCookies);

//app.use(moment);


app.mount('#app');
