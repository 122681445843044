<template>

<div v-if="showUnderConstruction" class="under-c">
      <p>
        Under construction
      </p>
      <p>
        Patience, my little Padawan
      </p>
</div>
<div v-else class="m--wrap">  
  <div class="invalid-wrap">
    <p>
      Low resolution browsers not supported.<br/>
      Please use desktop browser.
    </p>
  </div>
  <div v-if="browserLock" class="invalid-bro">
    <p>
      Unsupported browser<br/>
      the only right choice: <a target="_blank" href="https://www.google.com/chrome/">Download</a>
    </p>
  </div>
  <router-view />
</div>

</template>

<script>
//import shared from "@/shared";

export default {
 components: { },
 name: "App",

 data: function () {
    return {
      browserD: null,
      browserLock: false,
      userFunction: [],
      UserId: 0,
      showUnderConstruction: false

    };
  },

  created() {
    // this.CookieRemove = shared.CookieRemove;



    // const myCookie = this.$cookies.get("_ts_const");

    // if (myCookie != 'IDDQD') {
    //   this.showUnderConstruction = true;
    // }

  

  },

  mounted() {
    // to ma być wyłączone !!!
    //this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
    //this.CookieCheckGoToLogin();  
    // console.log('AppView: jest');
    // console.dir(process.env);
    // console.log('VUE_APP_API_URL: '+process.env.VUE_APP_API_URL);
    
    
    this.BrowserDetect();

    


    //console.log('browserLock: '+ this.browserLock);

  },


  methods: {

    BrowserDetect() {
                 
        var browserName = (function (agent) {        
          switch (true) {
            case agent.indexOf("edge") > -1: return "MSEdge";
            case agent.indexOf("edg/") > -1: return "Edge";
            case agent.indexOf("opr") > -1 && !!window.opr: return "Opera";
            case agent.indexOf("chrome") > -1 && !!window.chrome: return "Chrome";
            case agent.indexOf("trident") > -1: return "MSIE";
            case agent.indexOf("firefox") > -1: return "Firefox";
            case agent.indexOf("safari") > -1: return "Safari";
          default: return "other";
          }
        })(window.navigator.userAgent.toLowerCase());
         
        this.browserD = browserName;

        if (this.browserD === 'Edge' || this.browserD === 'Chrome' || this.browserD === 'Firefox' || this.browserD === 'Opera' || this.browserD === 'Safari' ) {
          this.browserLock = false;
        }
        else {
          this.browserLock = true;
        }


    },

    
  }

}



</script>

<style>


  @font-face {
    font-family: 'Anton-Regular';
    src: url(./assets/fonts/Anton-Regular.woff) format('woff'),
        url(./assets/fonts/Anton-Regular.woff2) format('woff2');
  }
  @font-face {
    font-family: 'Lato-Regular';
    src: url(./assets/fonts/Lato-Regular.woff) format('woff'),
        url(./assets/fonts/Lato-Regular.woff2) format('woff2');
  }
  @font-face {
    font-family: 'Lato-Italic';
    src: url(./assets/fonts/Lato-Italic.woff) format('woff'),
        url(./assets/fonts/Lato-Italic.woff2) format('woff2');
  }
  @font-face {
    font-family: 'Lato-Bold';
    src: url(./assets/fonts/Lato-Bold.woff) format('woff'),
        url(./assets/fonts/Lato-Bold.woff2) format('woff2');
  }
  @font-face {
    font-family: 'Lato-BoldItalic';
    src: url(./assets/fonts/Lato-BoldItalic.woff) format('woff'),
        url(./assets/fonts/Lato-BoldItalic.woff2) format('woff2');
  }

html, body, #app {
  height: 100%;
}

body {
  font-family: 'Lato-Regular';
  margin: 0;
  /* overflow: hidden; */
}

#app {
  font-family: 'Lato-Regular', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
    
}

/* #app::before {
    content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.1;
      background-image: url('assets/login-bg.jpg');
      background-repeat: no-repeat;
      background-position: 0 86%;
      background-size: cover;
      z-index: -1;
} */

.invalid-wrap,
.invalid-bro {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.88);
  z-index: 10;
  display:none;
  color: white;
  font-size: 32px;
  line-height: 50px;
}

.invalid-bro {
  display: block;
}

.invalid-wrap p,
.invalid-bro p {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.f-bold {
  font-weight: 900;
}

.under-c {

    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    color: red;
    font-size: 58px;
    flex-direction: column;

    background-image: url('assets/Yoda-Off.jpg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 23%;
}

.under-c p {
  margin: 6px 0;
}

.under-c p:nth-child(2) {
  font-size: 30px;
}

.m--wrap {
  height: 100%;
}


@media (max-width: 600px) {

  .invalid-wrap {
    font-size: 22px;
    line-height: 36px;
  }
}


@media (max-width: 1200px) {

  .invalid-wrap {
    display: block;
  }
}

/* 
.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: white;
  opacity: 0.6;
  z-index: 999;
}

.lds-hourglass {
  display: inline-block;

  width: 80px;
  height: 80px;
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid black;
  border-color: black transparent black transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
} */

body.dark-mode {
  background-color: #2c3e50;
}





</style>
