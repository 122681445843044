import axios from 'axios';
import store from "../store";
import router from "../router";
//import shared from "@/shared.js";




const API_URL = store.getters.getApiUrl;


// axios.interceptors.request.use(function (config) {
//       // Do something before request is sent
//       store.commit('setAxiosState', 1);
//       return config;
//     }, function (error) {
//       // Do something with request error
//       return Promise.reject(error);
//     });

// axios.interceptors.response.use(function (response) {
//       // Any status code that lie within the range of 2xx cause this function to trigger
//       // Do something with response data
//       store.commit('setAxiosState', 0);
//       return response;
//     }, function (error) {
//       // Any status codes that falls outside the range of 2xx cause this function to trigger
//       // Do something with response error
//       return Promise.reject(error);
//     });

function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    }


axios.interceptors.request.use((config) => {
      const userFunctionInLocalStorage = JSON.parse(localStorage.getItem('userFunction')) || [];
      const userFunctionInStore = store.getters.userFunction;

      const AgencyIdInCookie = getCookie('_ts_agency_id');
      const AgencyIdInStore = store.getters.userData.agencyId;
      if (AgencyIdInCookie != AgencyIdInStore) {
            document.cookie = "_ts_agency_id=" + AgencyIdInStore + "; max-age=" + 90*24*60*60;
      }

      const RoleInCookie = getCookie('_ts_role');
      const RoleInStore = store.getters.userData.role;
      if (RoleInCookie != RoleInStore) {
            document.cookie = "_ts_role=" + RoleInStore + "; max-age=" + 90*24*60*60;
      }

      const TeamIdInCookie = getCookie('_ts_team_id');
      const TeamIdInStore = store.getters.userData.teamId;
      if (TeamIdInCookie != TeamIdInStore) {
            document.cookie = "_ts_team_id=" + TeamIdInStore + "; max-age=" + 90*24*60*60;
      }

      const UserIdInCookie = getCookie('_ts_user_id');
      const UserIdInStore = store.getters.userData.userId;
      if (UserIdInCookie != UserIdInStore) {
            document.cookie = "_ts_user_id=" + UserIdInStore + "; max-age=" + 90*24*60*60;
      }
      

      // const userSettingsInLocalStorage = JSON.parse(localStorage.getItem('userSettings')) || [];
      // const userSettingsInStore = store.getters.userSettings;

  
      if (JSON.stringify(userFunctionInLocalStorage) !== JSON.stringify(userFunctionInStore)) {
          
            return Promise.reject({customStatus: 'ERR-DIFF', message: 'There is a problem with your permissions in the app'});
      }

      // if (JSON.stringify(userSettingsInLocalStorage) !== JSON.stringify(userSettingsInStore)) {
          
      //       return Promise.reject({customStatus: 'ERR-SETT', message: 'There is a problem with your settings in the app'});
      // }
  
      return config;
  }, (error) => {
      return Promise.reject(error);
  })
  
  


axios.interceptors.response.use(
      (response) => {
        let v = response.headers['version'] || '0.00';
        let ls = localStorage.getItem('version');

        let uid = response.headers['uid'];
        let uidc = getCookie('_ts_user_id');
         console.log('uid: '+uid);
         console.log('uidc: '+uidc);
               
        if (ls === undefined || ls === null) {
            localStorage.setItem('version', v);         
        }
        else if(v !== ls){
            localStorage.setItem('version', v);
            // store.commit('setVersion', v);
            //shared.CookieRemove();
            
            //localStorage.clear();
            //window.location.reload(true); 

            document.cookie = "_ts_user_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            console.log('go to log');
            //router.push({name: "Login"});
            router.push({ name: "Login", query: { fromInterceptor: true } });
            //router.push({ name: "Login", params: { fromInterceptor: true } });
          
         }

      //    if (uid !== uidc) {
      //       document.cookie = "_ts_user_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      //       console.log('mismatched user id');
      //       router.push({ name: "Login", query: { fromInterceptor: true } });
      //    }
          
      return Promise.resolve(response);
    })



export default {
      async list(date) {
            const headers = store.getters.getAuthHeader;
            const response = await axios.post(API_URL + "entries/list", date, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async get(id) {
            const headers = store.getters.getAuthHeader;
            const response = await axios.get(API_URL + "entries/" + id, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },
      async add(entry) {
            entry.syslog = store.getters.userData.userName;
            const headers = store.getters.getAuthHeader;
            const response = await axios.post(API_URL + "entries/add", entry, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },
      async edit(entry) {
            entry.syslog = store.getters.userData.userName;
            const headers = store.getters.getAuthHeader;
            const response = await axios.put(API_URL + "entries/edit", entry, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },
      async delete(id) {
            const headers = store.getters.getAuthHeader;
            const syslog = store.getters.userData.userName;
            const response = await axios.delete(API_URL + "entries/delete/" + id + "/" + syslog, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },
      async dateSum(date) {
            const headers = store.getters.getAuthHeader;         
            const response = await axios.post(API_URL + "entries/date-sum", date, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },
      
      async hoList(date) {
            const headers = store.getters.getAuthHeader;
            const response = await axios.post(API_URL + "entries/hoList", date, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },
      
      async hoSet(data) {
            const headers = store.getters.getAuthHeader;
            const response = await axios.post(API_URL + "entries/hoSet", data, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },
}